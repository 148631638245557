<template>
  <v-form
    @submit.prevent="resetPassword"
    v-model="resetPasswordFormIsValid"
    ref="form"
    class="form"
  >
    <h2 class="form__title">Mot de passe</h2>
    <v-row>
      <v-col cols="12" md="6" class="py-1">
        <v-text-field
          v-model="formData.oldPassword"
          outlined
          label="Ancien mot de passe"
          persistent-placeholder
          dense
          :type="oldPasswordIconActive ? 'text' : 'password'"
          placeholder="**************************"
          :rules="[validations.required]"
        >
          <template v-slot:append>
            <v-icon
              :color="oldPasswordIconActive ? 'primary' : 'gray'"
              @click="oldPasswordIconActive = !oldPasswordIconActive"
            >
              {{ oldPasswordIconActive ? "mdi-eye-off" : "mdi-eye" }}
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="py-1">
        <v-text-field
          v-model="formData.newPassword"
          outlined
          :label="$t('labels.newPassword')"
          persistent-placeholder
          dense
          :type="newPasswordIconActive ? 'text' : 'password'"
          placeholder="**************************"
          :rules="[validations.required, validations.password]"
        >
          <template v-slot:append>
            <v-icon
              :color="newPasswordIconActive ? 'primary' : 'gray'"
              @click="newPasswordIconActive = !newPasswordIconActive"
            >
              {{ newPasswordIconActive ? "mdi-eye-off" : "mdi-eye" }}
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <div class="text-center pt-4">
      <v-btn
        color="info"
        depressed
        type="submit"
        :loading="loading"
        :disabled="loading"
      >
        {{ $t("userActions.confirm") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { UserService } from "@/services/user.service.js";
import validations from "@/validations";

export default {
  name: "PasswordForm",
  data() {
    return {
      loading: false,
      resetPasswordFormIsValid: false,
      formData: {
        newPassword: "",
        oldPassword: "",
      },
      newPasswordIconActive: false,
      oldPasswordIconActive: false,
    };
  },
  computed: {
    validations() {
      return validations;
    },
  },
  methods: {
    oldPasswordDoesMatch(val) {
      return val === this.formData.newPassword
        ? true
        : this.$t("validations.password.passwordDontMatch");
    },
    resetPassword() {
      this.$refs.form.validate();
      if (!this.resetPasswordFormIsValid) return;
      this.loading = true;
      UserService.changePassword(this.formData)
        .then(({ data }) => {
          this.$store.commit("authModule/SET_USER_PROFILE", data);
          this.$store.commit(
            "snackbarModule/SET_SUCCESS_MESSAGE",
            this.$t("snackbar.success.update")
          );
          Object.assign(this.$data, this.$options.data())
          this.$refs.form.resetValidation()
        })
        .catch(() => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style>
</style>